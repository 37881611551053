import * as React from "react";
import { useEffect } from "react";
import Topbar from "../components/Topbar";
import FirstView from "../views/FirstView";
import FootView from "../views/FootView";
import StepView from "../views/StepView";
import AIView from "../views/AIView";
import LifestyleView from "../views/LifestyleView";
import PartnersView from "../views/PartnersView";
import ContactView from "../views/ContactView";
import CarousellView from "../views/CarousellView";
import { Helmet } from "react-helmet";

const Homepage = (props: any) => {
  const ref = React.createRef();

  useEffect(() => {
    window.location.href = "https://www.saluto.ai";
  }, []);

  return <></>;
};

export default Homepage;
